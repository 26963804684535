.over_card {
    height: inherit;
    background-color: #64ffda;
    opacity: 0.70;
    transition: opacity 0.6s ease-in-out;
}

.over_card:hover {
    opacity: 0;
}


.cardSkill {
    height: 28rem;
    width: 24rem;
    min-width: 8rem;
    border: 2px solid #ccd6f6;
    border-radius: 0.75rem;
    font-size: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-evenly;
  }

  .cardSkill:before {
    background: linear-gradient(
      130deg,
      transparent 0% 20%, 
      rgb(10, 25, 80) 44% 60%,
      rgb(100, 255, 218) 60% 100%
      
    );
    background-size: 300% 300%;
    background-position: 0% 0%;
    content: "";
    height: 100%;
    position: absolute;
    transition: background-position 1s ease-in-out;
    width: 100%;
    border-radius: 0.75rem;
    transform: scale(1.02);
  }

  .cardSkill:hover::before {
    background-position: 100% 100%;
    transition: 1000ms ease-in-out;
  }

  .cardSkill:hover .first_row {
    transform: translateX(100%);
  }
  
  .cardSkill:hover .second_row {
    transform: translateX(-100%);
  }
  
  .cardSkill:hover .third_row {
    transform: translateX(100%);
  }

  .first_row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    left: -100%;
    transition: all 0.8s 0.2s;
  }
  
  .second_row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    background-color: transparent;
    right: -100%;
    transition: all 0.8s 0.3s;
  }
  
  .third_row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    left: -100%;
    transition: all 0.8s 0.4s;
  }

  .cardSkill:hover .skillText {
    opacity: 0;
    transform: translateX(-100%);
  }


  .cardSkill:hover .element {
    opacity: 1;
  }


  .element {
    opacity: 0;
    color: #0a192f;
    transition: opacity 0.8s ease-in-out;
  }
  

  .skillText {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 10%;
    color: rgb(100, 255, 218);
    font-size: 2.5vmin;
    font-weight: 600;
    font-size: xx-large;
    transition: opacity 0.3s 0.5s, transform 0.05s 0.50s;
  }
  .flip-card {
    background-color: transparent;
    width: 100px;
    height: 100px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: transparent;
    color: black;
  }
  
  .flip-card-back {
    background-color: rgb(100, 255, 218);
    color: white;
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }