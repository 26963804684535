.animation_main {
    transform: translateX(0rem);
    transition: transform 0.4s ease-in-out;
  }

  .animation_main_span {
    transform: translateX(0rem);
    transition: transform 0.4s 0.1s ease-in-out;
  }

  .animation_profile_pic {
    transform: translateX(0rem);
    transition: transform 0.4s 0.1s ease-in-out;
  }

  .animation_cards {
    transform: translateX(0rem);
    transition: transform 0.6s ease-in-out;
  }

  .animation_cursus {
    transform: translateX(0rem);
    transition: transform 0.4s ease-in-out;
  }

  .animation_about {
    transform: translateX(0rem);
    transition: transform 0.4s 0.2s ease-in-out;
  }

  .animation_strenght {
    transform: translateX(0rem);
    transition: transform 0.4s 0.4s ease-in-out;
  }

  .animation_jobs {
    transform: translateX(0rem);
    transition: transform 0.4s 0.6s ease-in-out;
  }

  .animation_form {
    transform: translateY(0rem);
    transition: transform 0.8s ease-in-out;
  }
  .animation_contact {
    transform: translateY(0rem);
    transition: transform 0.7s 0.250s ease-in-out;
  }